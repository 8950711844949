<template>
  <div class="mobile-container">
    <mobileStatusBar title="编辑昵称" />
    <div class="input-item">
      <div class="input"><input v-model="nickname" type="text" placeholder="请输入昵称" /></div>
    </div>
    <!-- eslint-disable -->
    <div class="tips-item">请设置2-24个字符，不包括@<>/等无效字符</div>
    <div class="flex-1"></div>
    <div class="btn-item public-btn" @click="handleSubmit">
      <van-loading v-if="submiting" color="#ffffff" />
      <span v-else>保存</span>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import mobileStatusBar from '../../components/mobileStatusBar.vue'

export default {
  name: "ResetNickname",
  components: {
    mobileStatusBar
  },
  data() {
    return {
      submiting: false,
      nickname: ''
    }
  },
  computed: {
    ...mapState(['userInfo', 'websiteInfo'])
  },
  watch: {
    
  },
  mounted() {
    this.nickname = this.userInfo.Nickname
  },
  methods: {
    ...mapActions(['GetUserInfo']),
    async handleSubmit() {
      const { nickname } = this
      if (nickname == '') {
        this.$toast('请输入昵称')
        return
      }
      this.submiting = true
      var res = await this.$API.userInfo.post({
        Switch: 2,
        Nickname: nickname
      })
      this.submiting = false
      if (res.error == 0) {
        this.$toast.success(res.info)
        this.GetUserInfo()
        this.$router.go(-1)
        return true
      } else {
        this.$dialog.alert({
          type: 'danger',
          title: '提示',
          message: res.info,
          confirmButtonText: '确定',
        })
        return false
      }
    }
  },
};
</script>

<style lang="less" scoped>
.mobile-container {
  width: 100%;
  height: 100vh;
  background-color: #f7f7f7;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}
.input-item {
  width: 6.38rem;
  height: .88rem;
  margin-top: .32rem;
  padding: 0 .24rem;
  background-color: #fff;
  border-radius: .16rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .input {
    flex: 1;
  }
}
.tips-item {
  margin-top: .24rem;
  width: 6.62rem;
  line-height: .34rem;
  color: #b3b3b3;
  font-size: .24rem;
}
.btn-item {
  cursor: pointer;
  margin-bottom: 1.6rem;
  width: 6.86rem;
  height: .88rem;
  border-radius: .12rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: .3rem;
  font-weight: bold;
  color: #fff;
}
</style>